import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../config/index';
import {connect, setStore} from "trim-redux";
import {Link, withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import HeaderTags from "../../components/elements/HeaderTags";
import Box from "@material-ui/core/Box/Box";
import AuthLayout from "../../components/layouts/AuthLayout";
import ChangeMobileModal from "./components/ChangeMobileModal";
import {translate} from "../../../config/lang";

class EmailVerify extends React.Component {
    constructor(props){
        super(props);
        let user = this.props.user;
        if(user.mobile_confirmed === 1){
            this.props.history.push('/')
        }
        this.state={
            fields: {
                token:0,
            },
            errors:{},
            SubmitLoading:false,
            success:false,
            minutes: 5,
            seconds: 0,
        };
    }
    async componentDidMount(){
        await this.makeToken();

    }
    componentWillUnmount() {
        clearInterval(this.myInterval)
    }
    async makeToken(){
        const loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`

            }
        };
        await axios.get(`${defaultVariabls.url}/user/sendVerificationCodeToEmail`, config)
            .then(response =>{
                    this.setState({
                        minutes:5,
                        seconds:0
                    })
                this.myInterval = setInterval(() => {
                    const { seconds, minutes } = this.state

                    if (seconds > 0) {
                        this.setState(({ seconds }) => ({
                            seconds: seconds - 1
                        }))
                    }
                    if (seconds === 0) {
                        if (minutes === 0) {
                            clearInterval(this.myInterval)
                        } else {
                            this.setState(({ minutes }) => ({
                                minutes: minutes - 1,
                                seconds: 59
                            }))
                        }
                    }
                }, 1000)
            })
            .catch(error =>{
                console.log(error.response)
                this.setState({
                    SubmitLoading:false

                })
                this.props.enqueueSnackbar(translate('makingTokenError'), {
                    variant: 'error',
                });
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({fields});
        if(fields['token'].length === 5) {
            this.setState({
                SubmitLoading:true
            })
            this.handleRequest()
        }
    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //token
        if(validator.isEmpty(fields.token)){
            formIsValid = false;
            errors['token'] = translate('emptyField');
        }
        this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });
    }
    handleLogout(){
        localStorage.removeItem('ACT');
        localStorage.removeItem('RET');
        setStore({
            isAuth : false,
            user : null,
        });
        window.location.replace(`${defaultVariabls.website}/logOutFromPanel`);
    }
    async handleRequest(){
        const {token} = this.state.fields;
        const loginToken = localStorage.getItem('ACT');
        let data = new FormData();
        data.append('token', token);
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`

            }
        };
        await axios.post(`${defaultVariabls.url}/user/checkToken`, data, config)
            .then(async response =>{
                await this.props.enqueueSnackbar(translate('mobileVerified'), {
                    variant: 'success',
                });
                await setStore({
                    isAuth : true,
                    user : response.data,
                });
                await window.location.replace(`${defaultVariabls.website}/redirect`);
            })
            .catch(error =>{
                this.setState({
                    SubmitLoading:false

                })
                this.props.enqueueSnackbar(translate('wrongToken'), {
                    variant: 'error',
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }

    render() {
        const {classes} = this.props;
        const {errors,SubmitLoading,success,minutes,seconds} = this.state;
        return (
            <AuthLayout>
                <HeaderTags title={translate('emailVerify')}/>

                <Grid item md={10} lg={8}>
                    <Box mb={{xs: 3, xl: 8}}  fontSize={20}>
                        <strong className={classes.auth_title}>
                            {translate('emailVerify')}
                        </strong>
                    </Box>
                    <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
                        <Grid container spacing={1} justify="center" alignItems='center'>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    required
                                    fullWidth
                                    color="secondary"
                                    variant="outlined"
                                    id="token"
                                    label={translate('token')}
                                    name="token"
                                    margin="normal"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['token']}
                                    helperText={errors['token']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="left" color="textSecondary">
                                    {translate('sendTokenText1')} <strong dir="ltr">{this.props.user.email}</strong> {translate('sendTokenText2')}
                                </Typography>
                                {
                                    minutes !== 0 || seconds !== 0 ? <Typography align="left" color="textSecondary">{translate('sendTokenText3')}: <strong>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</strong> </Typography> : <Button color="secondary" onClick={this.makeToken.bind(this)}>{translate('sendAgain')}</Button>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    style={{width:"100%"}}
                                    onClick = {this.handleSubmit.bind(this)}
                                    loading = {SubmitLoading}
                                    success = {success}
                                >
                                    {translate('confirm')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6} className={classes.textLeft}>
                                <Button size={"small"} component={Link} to={"/verify-mobile"} >
                                    {translate('verifyWithMobile')}
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={6} className={classes.textRight}>
                                <Button size={"small"} onClick={this.handleLogout.bind(this)} color="secondary"> {translate('logOut')}</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </AuthLayout>
        );

    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(EmailVerify))));